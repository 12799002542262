import React from "react";
import { ContactTop, ContactTopTitle, Line, ContactAddress, ContactMap, ContactBottom, ContactInfo, ContactBottomImg } from "../components/contact/contact.styles";
import Layout from "../components/layout";
import ContactForm from "../components/contact/contact-form";
import ContactSocial from "../components/contact/contact-social";
import BtnLink from "../components/elements/BtnLink";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Contact = () => {
  return (
      <GoogleReCaptchaProvider reCaptchaKey={'6Lfc3N0ZAAAAAMTwEgiMjq1SiikUToWBuU_jGHXb'}>
      <Layout title={'Kontakt'}>
        <ContactTop>
          <div className="container">
            <ContactTopTitle>Znajdź nas</ContactTopTitle>
            <Line/>
            <ContactAddress>
              <strong>adres salonu:</strong>
              <span>ul. Ludwika Chmury 4</span>
              <span>35-213 Rzeszów</span>
            </ContactAddress>

            <ContactAddress>
              <strong>godziny otwarcia:</strong>
              <span>wtorek-piątek 10:00-18:00</span>
            </ContactAddress>

          </div>

          <ContactMap>
            <img src="/images/dodge_map.png" alt=""/>

            <BtnLink className={`btn-map`} url="https://goo.gl/maps/HgRSrNgdnnKUGCddA" label="zobacz na mapie"/>
          </ContactMap>

        </ContactTop>

        <ContactBottom>
          <div className="container">
            <h2>skontaktuj się z nami</h2>
          </div>
          <div className="container">

            <ContactInfo>
              <h4>Doradca handlowy</h4>
              <Line/>

              <strong>Miłosz Opaliński</strong>
              <span>tel.: <a href="tel:+48532081817">+48 532 081 817</a></span>
              <span>e-mail: <a href="mailto:salon@dodge.rzeszow.pl">salon@dodge.rzeszow.pl</a></span>
              <br/>

              <h4>serwis i części</h4>
              <Line marginBottom="2rem"/>

              <strong>Marek Świeboda</strong>
              <span>tel.: <a href="tel:+48532081814">+48 532 081 814</a></span>
              <span>e-mail: <a href="mailto:serwis@dodge.rzeszow.pl">serwis@dodge.rzeszow.pl</a></span>

              <ContactSocial/>
            </ContactInfo>

            <ContactForm/>

            <ContactBottomImg>
              <img src="/images/kontakt_bg_ram.png" alt=""/>
            </ContactBottomImg>
          </div>
        </ContactBottom>
      </Layout>
      </GoogleReCaptchaProvider>
  )
}

export default Contact